.breadcrumb {
  margin: 50px 0;
}
.neighbors {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
  a {
    color: #363636;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
}
