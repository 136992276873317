.front {
  &-catalog {
    &-title {
      padding: 20px 5px 17px 5px;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      background-color: #ff0000;
      font-size: 15px;
      font-weight: bold;
    }
  }
}
