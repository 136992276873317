.menu {
  &-catalog {
    margin-bottom: 30px;
    &-item {
      a {
        color: #000;
        display: block;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        border-bottom-width: 0px;
        padding: 16px 0 16px 20px;
        border: 1px solid #e5e5e5;
        transition: color .4s;
        &.active {
          border-color: #ff191e;
          color: #ff191e;
        }
        &:hover {
          color: #ff191e;
        }
        &:last-child {
          border-bottom-width: 1px;
        }
      }
      a {

      }
    }
  }
}
