.catalog {
  &-main {
    &-row {
      display: flex;
      flex-wrap: wrap;
    }
    &-col {
      width: 25%;
      @media (max-width: 992px) {
        width: 50%;
      }
      @media (max-width: 576px) {
        width: 100%;
      }
    }
    &-item {
      display: block;
      color: #363636;
      text-align: center;
      margin-bottom: 50px;
      text-decoration: none;
      transition: transform 0.4s, color 0.4s;
      &:hover, &:focus {
        color: inherit;
        text-decoration: none;
        transform: translate3d(0, -6px, 0);
      }
      &:active {
        color: #de352e;
      }
    }
    &-image {
      margin-bottom: 20px;
    }
    &-title {
      font-size: 16px;
      font-weight: bold;
    }
  }
  &-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  &-col {
    display: flex;
    padding: 0 15px;
    width: 33.33333%;
    text-align: center;
    margin-bottom: 50px;
    justify-content: center;
    &-double {
      width: 50%;
    }
    @media (max-width: 1200px) {
      width: 50%;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  &-item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: transform 0.4s;
    &:hover {
      transform: translate3d(0, -6px, 0);
    }
    &-image {
      margin-bottom: 15px;
      .catalog-furnitura & {
        display: flex;
        height: 100%;
        min-height: 270px;
        align-items: center;
        justify-content: center;
      }
    }
    &-title {
      a {
        color: #000;
        display: block;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 15px;
      }
    }
    &-price {
      font-weight: 500;
      font-size: 16px;
      &-wrapper {
        margin-bottom: 15px;
      }
      &-old {
        color: #ff191e;
        font-style: italic;
        text-decoration: line-through;
      }
    }
    &-button {
      width: 100%;
      padding: 10px;
      color: #ff191e;
      font-size: 15px;
      font-weight: 500;
      border-radius: 0;
      background-color: #fff;
      text-transform: uppercase;
      border: 1px solid #ff191e;
      transition: background-color 0.4s, color 0.4s;
      &:hover {
        background-color: #ff191e;
        color: #fff;
      }
    }
    &-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &-filter {
    padding: 20px;
    border: 1px solid #e5e5e5;
    fieldset {
      margin-bottom: 20px;
    }
    .filter_title {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      text-transform: inherit;
    }
  }
  &-inner {
    display: flex;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    &__col {
      @media (max-width: 992px) {
        width: 50%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    &__imageblock {
      width: 100%;
      max-width: 400px;
      margin-right: 30px;
      margin-bottom: 50px;
      @media (max-width: 992px) {
        width: 40%;
      }
      @media (max-width: 768px) {
        width: 100%;
        margin-right: 0;
      }
      img {
        max-width: 100%;
      }
    }
    &__priceblock {
      width: 100%;
    }
    &-gallery {
      &-item {
        width: 100%;
        text-align: center;
      }
    }
    &__title {
      margin-top: 0;
      line-height: 1;
      font-size: 30px;
      margin-bottom: 20px;
    }
    &-button {
      &-red {
        color: #ff191e;
        font-size: 15px;
        cursor: pointer;
        font-weight: 500;
        border-radius: 0;
        padding: 10px 30px;
        margin-bottom: 15px;
        display: inline-block;
        background-color: #fff;
        text-transform: uppercase;
        border: 1px solid #ff191e;
        transition: background-color 0.4s, color 0.4s;
        &:hover {
          background-color: #ff191e;
          color: #fff;
        }
      }
    }
    &-price {
      display: flex;
      font-size: 16px;
      &__old {
        color: #de352e;
        font-style: italic;
        text-decoration: line-through;
        font-size: 14px;
      }
      &__title {
        margin-right: 10px;
      }
    }
  }
  &-other {

  }
}
